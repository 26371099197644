.form-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 30px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    z-index: 9999;
}

.form-modal h2 {
    margin-top: 0;
    font-size: 24px;
}

.form-modal label {
    display: block;
    margin-bottom: 10px;
}

.form-modal input[type="text"],
.form-modal textarea {
    width: 100%;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.form-modal textarea {
    height: 100px;
    resize: none;
}

.form-modal button[type="submit"] {
    background-color: #0077c0;
    color: #fff;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-right: 10px;
}

.form-modal button[type="cancel"]:hover {
    background-color: red;
}

.form-modal button[type="cancel"] {
    background-color: red;
    color: #fff;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-right: 10px;
}

.form-modal button[type="submit"]:hover {
    background-color: #005d99;
}

.open-form-button {
    background-color: #007bff !important;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    /* margin-top: 10px; */

}

.open-form-button:hover {
    background-color: #0069d9 !important;
}

.form-modal button[type="button"] {
    background-color: red;
    color: white;
    border: none;
    padding: 10px 20px;
    margin-right: 10px;
    cursor: pointer;
    margin-top: 10px;
}