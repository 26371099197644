header {
    background-color: #333;
    color: #fff;
    padding: 10px;

}

nav {
    border-bottom: 1px solid #fff;
    margin-bottom: 10px;
    padding: 10px;

    display: flex;
    align-items: center;
    justify-content: space-between;

}

nav h1 {
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    margin: 0;
    padding: 10px;
}


nav ul {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    margin: 0;
    padding: 0;
}

nav ul li {
    margin-right: 10px;
}

nav ul li a {
    color: #fff;
    text-decoration: none;
}


/* Search bar styles */
.search-bar {
    display: flex;
    align-items: center;
    margin-bottom: 20px;


}

.search-bar input[type="text"] {
    padding: 10px;
    border-radius: 5px;
    border: none;
    margin-right: 10px;
    width: 100%;
    max-width: 400px;
}

.search-bar button {
    padding: 10px;
    background-color: #4CAF50;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

/* Filter button styles */
.filter-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.filter-buttons button {
    padding: 10px;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 5px;
    margin-left: 10px;
    cursor: pointer;
}

.nav-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.nav-buttons button {
    padding: 10px;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 5px;
    margin-left: 10px;
    cursor: pointer;
}

.nav-buttons button {
    background-color: #4CAF50;

    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin-left: 10px;
    cursor: pointer;
    border-radius: 5px;
}

/* Form modal styles */
/* .form-modal-button {
    background-color: #0074D9;
    color: #FFF;
    border: none;
    padding: 8px 16px;
    font-size: 16px;
    cursor: pointer;
}

.form-modal-button:hover {
    background-color: #005EB8;
} */

.nav-buttons button .form-modal-button {

    background-color: #0074D9;

}