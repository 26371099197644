.card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 16px;
    width: 300px;

    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 300px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}



.card-title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 8px;
}

.card-text {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 16px;
}

.card-button {
    background-color: #0077cc;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    font-size: 1rem;
    padding: 8px 16px;
    transition: background-color 0.3s ease;
}

.card-button:hover {
    background-color: #0052a9;
}


.card img {
    width: 100%;
    max-width: 200px;
    height: auto;
    margin-bottom: 20px;
}


.card h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.card p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
}

.card button {
    padding: 10px 20px;
    background-color: #0077cc;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
}

.card button:hover {
    background-color: #005fa3;
}

/* Modal section */
.Modal {
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    padding: 20px;
    width: 400px;
    max-width: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.Modal h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.Modal p {
    margin: 10px 0;
}

.Modal button {
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    font-size: 1rem;
    cursor: pointer;
}

.Modal button:hover {
    background-color: #0062cc;
}