#content-1 {
  background-color: #eee;
  padding: 20px;
}

.map-container {
  position: absolute;
  height: 600px;
  width: 850px;
}

.sidebarStyle {
  display: inline-block;
  position: absolute;
  margin-left: 80px;
  margin-top: 10px;
  background-color: #404040;
  color: #ffffff;
  z-index: 1 !important;
  padding: 6px;
  font-weight: bold;
}

.marker {
  background-image: url('../public/mapbox-icon.png');
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

.mapboxgl-popup {
  max-width: 200px;
}

.mapboxgl-popup-content {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
}